import React, { useMemo, useState } from 'react';
import CustomDateTimePicker from '../../../../../components/CustomDateTimePicker/CustomDateTimePicker';
import { Col, Form, Row } from 'react-bootstrap';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control;
  errors: {
    nextAppointmentDate: { message: string; type: string };
    nextAppointmentValue: { message: string; type: string };
  };
  setValue: (key: string, value: any) => void;
  data?: {
    nextAppointment: string;
    nextAppointmentDate: string;
    nextAppointmentValue: boolean;
  };
};

function NextAppointment({ control, data, setValue, errors }: Props) {
  const { t } = useTranslation();

  const [nextAppointmentValue, setNextAppointmentValue] = useState<boolean | undefined>();

  const nextAppointmentValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: nextAppointmentValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: nextAppointmentValue === false,
        value: false,
      },
    ],
    [nextAppointmentValue]
  );

  return (
    <Row>
      <Col>
        <CustomRadioGroup
          label={t('examinations:fmdExam.general.nextAppointment')}
          labelClass="d-block"
          items={nextAppointmentValues}
          groupProps={{ className: 'mb-3' }}
          controlProps={{
            name: 'nextAppointmentValue',
            control,
            rules: { validate: v => v !== undefined || 'required' },
            defaultValue: data?.nextAppointmentValue,
          }}
          onChange={v => {
            setNextAppointmentValue(v as boolean);
            setValue('nextAppointmentValue', v);
          }}
        />
      </Col>
      <Col>
        <Form.Group className="mb-2">
          <Form.Label>{t('examinations:fmdExam.general.nextAppointmentDate')}</Form.Label>
          <CustomDateTimePicker
            controlProps={{ control, name: 'nextAppointmentDate' }}
            error={errors?.nextAppointmentDate}
            minDate={new Date()}
            range={true}
            format="dd/MM/yyyy"
            placeholderText="dd/MM/yyyy - dd/MM/yyyy"
            onChange={v => {
              setValue('nextAppointmentDate', v);
            }}
          />
          {errors?.nextAppointmentDate && (
            <Form.Control.Feedback type="invalid">
              {(errors?.nextAppointmentDate.message && t(`errors:validationMessages.${errors?.nextAppointmentDate.message}`)) ||
                (errors?.nextAppointmentDate.type === 'required' && (
                  <span id={'error-date'}>{t('errors:validationMessages.required')}</span>
                ))}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
}

export default NextAppointment;
