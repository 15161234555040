import moment from 'moment';
import React, { useState } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Trans, useTranslation } from 'react-i18next';
import { ExaminationItemType } from './Examination';

type Props = {
  item: ExaminationItemType;
};

function ExaminationHeader({ item }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const decoratedOnClick = useAccordionButton(item.id, () => {
    setOpen(!open);
  });

  if (!item.data) { 
    return null;
  }
  
  const dateParts = item.data.date.split('/');

  return (
    <div className={'accordion-button p-0' + (!open ? ' collapsed' : '')} onClick={decoratedOnClick}>
      <div className="examination-header">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0 w-75 cursor-pointer">{t(`examinations:types.${item.type}`)}</h6>
          <span className="text-muted d-block me-4">
            {moment(new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])).format('D MMMM, YYYY')}
          </span>
        </div>
        <p>
          {item.data.nurse ? (
            <span className="badge badge-soft-purple me-1">
              <Trans i18nKey="examinations:nurseTag" components={{ bold: <b></b>, name: item.data.nurse.fullName }} />
            </span>
          ) : null}
          {item.data.fmd ? (
            <span className="badge badge-soft-purple me-1">
              <Trans i18nKey="examinations:fmdTag" components={{ bold: <b></b>, name: item.data.fmd.fullName }} />
            </span>
          ) : null}
          {item.data.regionalDoctor ? (
            <span className="badge badge-soft-purple me-1">
              <Trans i18nKey="examinations:doctorTag" components={{ bold: <b></b>, name: item.data.regionalDoctor.fullName }} />
            </span>
          ) : null}
          {item.data.hasIssue && item.type === 'schoolscreening' ? (
            <span className="badge badge-soft-danger">{t('examinations:visionState.hasIssue')}</span>
          ) : null}
          {item.data.hasIssue === false ? (
            <span className="badge badge-soft-success">{t('examinations:visionState.normalVision')}</span>
          ) : null}
        </p>
      </div>
    </div>
  );
}

ExaminationHeader.propTypes = {};

export default ExaminationHeader;
