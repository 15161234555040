import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { RegDExamSubProps } from '../../AddREGDExam';
import useCloudinaryWidget, { AttachmentInfoType } from '../../../../../../hooks/useCloudinaryWidget';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import FileThumbnail, { FileThumbnailType } from '../../../../../../components/FileThumbnail/FileThumbnail';
import usePatient from '../../../../../../hooks/usePatient';

const Autorefractometry = ({ patientId, register, setValue, data, control, errors, clearErrors }: RegDExamSubProps) => {
  const { t } = useTranslation();
  const [sValue, setSValue] = useState<boolean>();

  const {
    showWidget: showAutorefWidget,
    files: autoRefFiles,
    tick,
  } = useCloudinaryWidget({
    folder: `patient/${patientId}/regional-exam/autorefFiles/`,
  });

  const stateValues = useMemo(
    () => [
      {
        label: t('yes'),
        checked: sValue === true,
        value: true,
      },
      {
        label: t('no'),
        checked: sValue === false,
        value: false,
      },
    ],
    [sValue]
  );

  useEffect(() => {
    if (autoRefFiles?.length) {
      setValue('autorefractometryFiles', autoRefFiles);
      clearErrors('autorefractometryFiles');
    }
  }, [autoRefFiles]);
  
  return (
    <Row className="mb-3">
      <Col md={6}>
        <h5 className="mb-3 m-0">{t('examinations:regdExam.autorefractometry.title')}</h5>
        <CustomRadioGroup
          label={t('examinations:regdExam.isPerformed')}
          labelClass="d-block"
          items={stateValues}
          controlProps={{
            name: 'autorefractometryState',
            control,
            defaultValue: data?.autorefractometryState,
          }}
          onChange={v => {
            clearErrors('autorefractometryState');
            setSValue(v);
            setValue('autorefractometryState', v);
          }}
        />
      </Col>
      <Col md={6}>
        <div className="file-box-content">
          <Controller
            control={control}
            name="autorefractometryFiles"
            rules={{ required: !!sValue }}
            defaultValue={data?.autorefractometryFiles}
            render={({ field: { value }, fieldState: { error } }) => (
              <>
                <Form.Group className={classNames({ 'is-invalid': !!error })}>
                  <Form.Label>{t('examinations:regdExam.autorefractometry.form')}</Form.Label>
                  <div
                    className={classNames({
                      'file-box': true,
                      'cursor-pointer': true,
                      'bg-light': !error,
                      'bg-soft-danger': error,
                      'border-danger': !!error,
                      'mb-0': true,
                    })}
                    onClick={showAutorefWidget}
                  >
                    <a className="download-icon-link">
                      <i className="las la-download file-download-icon text-white"></i>
                    </a>
                    <div className="text-center">
                      <i className="las la-plus text-info"></i>
                      <h6 className="text-truncate">{t('examinations:attachFile')}</h6>
                      <small className="text-muted">{t('examinations:attachFileHint')}</small>
                    </div>
                  </div>
                  {(value as AttachmentInfoType[])?.map(file => (
                    <FileThumbnail key={file.id} file={file} type={FileThumbnailType.square} actionIcon="times" />
                  ))}
                </Form.Group>
                {error ? (
                  <Form.Control.Feedback type="invalid">
                    {<span id="error-attached-file">{t('errors:validationMessages.required')}</span>}
                  </Form.Control.Feedback>
                ) : null}
              </>
            )}
          />
        </div>
      </Col>
    </Row>
  );
};
export default Autorefractometry;
