import classNames from 'classnames';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  error: object;
  minDate?: Date;
  maxDate?: Date;
  format?: string;
  range?: boolean;
  controlProps: ControlProps;
  showTimeSelect?: boolean;
  placeholderText?: string;
  onChange: (v: Date | [Date | null, Date | null] | null) => void;
};
type ControlProps = {
  name: string;
  control: Control;
  defaultValue?: Date | null;
  rules?: object;
};

function CustomDateTimePicker({
  controlProps,
  minDate,
  maxDate,
  format = 'yyyy-MM-dd HH:mm',
  range = false,
  showTimeSelect = false,
  placeholderText = '',
  onChange,
}: Props) {
  const { t } = useTranslation();

  const { defaultValue } = controlProps;

  const [startDate, setStartDate] = useState<Date | null | undefined>(defaultValue);
  const [endDate, setEndDate] = useState<Date | null | undefined>(null);

  return (
    <Controller
      {...controlProps}
      render={({ field: { onBlur, value }, fieldState: { invalid, error } }) => (
        <>
          <div className={classNames({ 'is-invalid': invalid })}>
            <ReactDatePicker
              selectsRange={range}
              // popperClassName="result-date-picker"
              className={`form-control ${error ? 'is-invalid' : ''}`}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={d => {
                if (Array.isArray(d)) {
                  setStartDate(d[0]);
                  setEndDate(d[1]);
                } else {
                  setStartDate(d);
                  setEndDate(d);
                }
                onChange(d);
              }}
              onBlur={onBlur}
              minDate={minDate}
              maxDate={maxDate}
              showTimeSelect={showTimeSelect}
              dateFormat={format}
              showDisabledMonthNavigation
              showYearDropdown
              placeholderText={placeholderText}
            />
          </div>
          {error && (
            <Form.Control.Feedback type="invalid">
              {(error.message && t(`errors:validationMessages.${error.message}`)) ||
                (error.type === 'required' && (
                  <span id={`error-${controlProps.name}`}>{t('errors:validationMessages.required')}</span>
                ))}
            </Form.Control.Feedback>
          )}
        </>
      )}
    />
  );
}

CustomDateTimePicker.propTypes = {};

export default CustomDateTimePicker;
