import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import CustomFormGroup from '../../../components/CustomFormGroup/CustomFormGroup';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import FilterMenu from '../../../components/FilterMenu/FilterMenu';
import { CountryRegions } from '../../../constants';

const Filter = ({ setParams, query }) => {
  const { t } = useTranslation();
  const [nurseList, setNursesList] = useState([]);
  const [fmdList, setFMDList] = useState([]);
  const [regOphtList, setRegOphtList] = useState([]);

  const [selectedNurse, setSelectedNurse] = useState();
  const [selectedFMD, setSelectedFMD] = useState();
  const [selectedRegDoctor, setSelectedRegDoctor] = useState();
  const [selectedRegion, setSelectedRegion] = useState();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    reset,
  } = useForm();

  const handleFilter = values => {
    const formValues = { ...values };

    values.uid === undefined && delete values.uid && query.delete('uid');
    !values.school && delete values.school && query.delete('school');
    !values.parentPhone && delete values.parentPhone && query.delete('parentPhone');
    !values.name && delete values.name && query.delete('name');
    !values.birthCertificateNumber && delete values.birthCertificateNumber && query.delete('birthCertificateNumber');
    !values.socialCardNumber && delete values.socialCardNumber && query.delete('socialCardNumber');
    !values.dob ? delete values.dob && query.delete('dob') : (formValues.dob = moment(values.dob).format('yyyy-MM-DD'));
    !values.date
      ? delete values.date && query.delete('date')
      : (formValues.date = moment(values.date).format('yyyy-MM-DD'));
    !selectedNurse && delete values.nurse && query.delete('nurse');
    !selectedFMD && delete values.fmd && query.delete('fmd');
    !selectedRegDoctor && delete values.regd && query.delete('regd');
    !selectedRegion && delete values.region && query.delete('region');

    formValues.nurse = selectedNurse ? selectedNurse.value : undefined;
    formValues.fmd = selectedFMD ? selectedFMD.value : undefined;
    formValues.regd = selectedRegDoctor ? selectedRegDoctor.value : undefined;
    formValues.region = selectedRegion ? selectedRegion.value : undefined;

    setParams(formValues);
  };

  const handleReset = () => {
    reset({
      name: null,
      birthCertificateNumber: null,
      socialCardNumber: null,
      dob: null,
    });

    setSelectedNurse(undefined);
    setSelectedFMD(undefined);
    setSelectedRegDoctor(undefined);
    setSelectedRegion(undefined);

    query.delete('name');
    query.delete('birthCertificateNumber');
    query.delete('socialCardNumber');
    query.delete('medInstitutionName');
    query.delete('familyDoctorName');
    query.delete('dob');

    setParams({});
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/user/list', { params: { role: 'nurse', all: true } });

        if (response.status === 200) {
          const nurseList = response.data.data.users;
          setNursesList(
            nurseList.map(_ => {
              return { value: _.id, label: _.fullName };
            })
          );
        }
      } catch (err) {
        console.log(err);
      }

      try {
        const response = await axios.get('/user/list', { params: { role: 'fmd', all: true } });

        if (response.status === 200) {
          const uList = response.data.data.users;
          setFMDList(
            uList.map(_ => {
              return { value: _.id, label: _.fullName };
            })
          );
        }
      } catch (err) {
        console.log(err);
      }

      try {
        const response = await axios.get('/user/list', { params: { role: 'regd', all: true } });

        if (response.status === 200) {
          const uList = response.data.data.users;
          setRegOphtList(
            uList.map(_ => {
              return { value: _.id, label: _.fullName };
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    } 
    fetchData();
  }, []);

  return (
    <FilterMenu>
      <Form onSubmit={handleSubmit(handleFilter)}>
        <ul className="list-unstyled components sd-body">
          <li>
            <CustomFormGroup
              label={t('patient:profile.uid')}
              groupProps={{ className: 'mb-3' }}
              error={errors.uid}
              fixedLabel={true}
              controlProps={{
                id: 'uid',
                name: 'uid',
                maxLength: 10,
              }}
              register={register('uid')}
            />
          </li>
          <li>
            <CustomFormGroup
              label={t('patient:profile.name')}
              groupProps={{ className: 'mb-3' }}
              error={errors.name}
              fixedLabel={true}
              controlProps={{
                id: 'name',
                name: 'name',
                maxLength: 100,
              }}
              register={register('name')}
            />
          </li>
          <li>
            <CustomFormGroup
              label={t('patient:profile.school')}
              groupProps={{ className: 'mb-3' }}
              error={errors.school}
              fixedLabel={true}
              controlProps={{
                id: 'school',
                name: 'school',
              }}
              register={register('school')}
            />
          </li>
          <li>
            <CustomFormGroup
              label={t('patient:adultContacts.mobilePhone')}
              groupProps={{ className: 'mb-3' }}
              error={errors.socialCardNumber}
              fixedLabel={true}
              controlProps={{
                id: 'parentPhone',
                name: 'parentPhone',
              }}
              register={register('parentPhone')}
            />
          </li>
          <li>
            <Form.Group className="mb-3">
              <Form.Label>{t('userRoles.nurse')}</Form.Label>
              {nurseList.length > 0 && (
                <Controller
                  name="nurse"
                  control={control}
                  render={({ field: { onChange } }) => {
                    return (
                      <CustomSelect
                        register={{ ...register('nurse') }}
                        name="nurse"
                        options={nurseList}
                        value={selectedNurse ? selectedNurse.value : null}
                        contentRenderer={() => (
                          <span className="text-size-smaller">{selectedNurse && selectedNurse.label}</span>
                        )}
                        onChange={selected => {
                          setSelectedNurse(selected[0]);
                          onChange(selected[0] ? selected[0].value : undefined);
                        }}
                      />
                    );
                  }}
                />
              )}
            </Form.Group>
          </li>
          <li>
            <Form.Group className="mb-3">
              <Form.Label>{t('userRoles.fmd')}</Form.Label>
              {fmdList.length > 0 && (
                <Controller
                  name="fmd"
                  control={control}
                  render={({ field: { onChange } }) => {
                    return (
                      <CustomSelect
                        register={{ ...register('fmd') }}
                        name="fmd"
                        options={fmdList}
                        value={selectedFMD ? selectedFMD.value : null}
                        contentRenderer={() => (
                          <span className="text-size-smaller">{selectedFMD && selectedFMD.label}</span>
                        )}
                        onChange={selected => {
                          setSelectedFMD(selected[0]);
                          onChange(selected[0] ? selected[0].value : undefined);
                        }}
                      />
                    );
                  }}
                />
              )}
            </Form.Group>
          </li>
          <li>
            <Form.Group className="mb-3">
              <Form.Label>{t('userRoles.regd')}</Form.Label>
              {regOphtList.length > 0 && (
                <Controller
                  name="regd"
                  control={control}
                  render={({ field: { onChange } }) => {
                    return (
                      <CustomSelect
                        register={{ ...register('regd') }}
                        name="regd"
                        options={regOphtList}
                        value={selectedRegDoctor ? selectedRegDoctor.value : null}
                        contentRenderer={() => (
                          <span className="text-size-smaller">{selectedRegDoctor && selectedRegDoctor.label}</span>
                        )}
                        onChange={selected => {
                          // setNursesList(val[0]);
                          setSelectedRegDoctor(selected[0]);
                          onChange(selected[0] ? selected[0].value : undefined);
                        }}
                      />
                    );
                  }}
                />
              )}
            </Form.Group>
          </li>
          <li>
            <Form.Group className="mb-3">
              <Form.Label>{t('address.region.title')}</Form.Label>
              <Controller
                name={'region'}
                control={control}
                defaultValue={null}
                value={selectedRegion ? selectedRegion.value : null}
                render={({ field: { onChange, name } }) => {
                  return (
                    <CustomSelect
                      name={name}
                      options={CountryRegions}
                      values={selectedRegion ? [selectedRegion] : []}
                      contentRenderer={() => (
                        <span className="text-size-smaller">{selectedRegion && selectedRegion.label}</span>
                      )}
                      onChange={selected => {
                        setSelectedRegion(selected[0]);
                        onChange(selected[0] ? selected[0].value : undefined);
                      }}
                    />
                  );
                }}
              />
            </Form.Group>
          </li>
          <li>
            <Form.Group className="mb-3">
              <Controller
                name={'hasIssue'}
                control={control}
                defaultValue={null}
                render={({ field: { onChange, name } }) => {
                  return (
                    <Form.Check
                      label={t('examinations:visionState.hasIssue')}
                      name={name}
                      id="filter-has-issue"
                      onChange={v => {
                        onChange(v);
                      }}
                      type="checkbox"
                    />
                  );
                }}
              />
            </Form.Group>
          </li>
          {/* <li>
            <FormGroup className="mb-3">
              <Form.Label>{'Screening Date'}</Form.Label>
              <Controller
                control={control}
                name="date"
                register={register('date')}
                render={({ field: { onChange, onBlur, value } }) => (
                  <div className={`${errors.date ? 'is-invalid' : ''}`}>
                    <DatePicker
                      name="dob"
                      placeholderText="yyyy-mm-dd"
                      className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                      selected={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                      showDisabledMonthNavigation
                      showYearDropdown
                    />
                  </div>
                )}
              />
            </FormGroup>
          </li> */}
          <li>
            <FormGroup>
              <Button type="submit" className="submit btn btn-de-primary">
                {t('buttons.apply')}
              </Button>
              <Button as="button" className="btn btn-de-danger" onClick={handleReset}>
                {t('buttons.reset')}
              </Button>
            </FormGroup>
          </li>
        </ul>
      </Form>
    </FilterMenu>
  );
};

export default Filter;
