import React, { useMemo, useState } from 'react';
import Separator from '../../../../../components/Separator/Separator';
import { Col, Form, Row } from 'react-bootstrap';
import { Control, Controller } from 'react-hook-form';
import CustomRadioGroup from '../../../../../components/CustomRadioGroup/CustomRadioGroup';
import { useTranslation } from 'react-i18next';
import { FMDExaminationResult } from '../../../../../constants';
import useUserList from '../../../../../hooks/useFMDList';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import CustomDateTimePicker from '../../../../../components/CustomDateTimePicker/CustomDateTimePicker';
import NextAppointment from './NextAppointment';
import CustomFormGroup from '../../../../../components/CustomFormGroup/CustomFormGroup';

type Props = {
  clearErrors: (name: string) => object;
  register: (name: string) => object;
  control: Control;
  errors: {
    date: { message: string; type: string };
    nextAppointmentDate: { message: string; type: string };
    dateRange: { message: string; type: string };
    nextAppointmentValue: { message: string; type: string };
  };
  setValue: (key: string, value: any) => void;
  data?: {
    date: string;
    result: number;
    funding: string;
    nextAppointment: string;
    nextAppointmentDate: string;
    nextAppointmentValue: boolean;
    treatment?: string;
    notes?: string;
  };
};

enum ResultValues {
  referredToRegionalDoctor = 1,
  treatmentByFMD = 2,
  notAgreeWithScreening = 3,
}

function General({ control, errors, register, setValue, data, clearErrors }: Props) {
  const { t } = useTranslation();
  const [resultValue, setResultValue] = useState();
  const [funding, setFunding] = useState();

  const { list: regdList } = useUserList('regd');

  const values = useMemo(
    () => [
      {
        label: t('examinations:fmdExam.general.resultValue1'),
        checked: resultValue === ResultValues.referredToRegionalDoctor,
        value: ResultValues.referredToRegionalDoctor,
      },
      {
        label: t('examinations:fmdExam.general.resultValue2'),
        checked: resultValue === ResultValues.treatmentByFMD,
        value: ResultValues.treatmentByFMD,
      },
      {
        label: t('examinations:fmdExam.general.resultValue3'),
        checked: resultValue === ResultValues.notAgreeWithScreening,
        value: ResultValues.notAgreeWithScreening,
      },
    ],
    [resultValue]
  );

  const fundingValues = useMemo(
    () => [
      {
        label: t('examinations:fmdExam.general.fundingValue1'),
        checked: funding === 'aecp',
        value: 'aecp',
      },
      {
        label: t('examinations:fmdExam.general.fundingValue2'),
        checked: funding === 'state',
        value: 'state',
      },
    ],
    [funding]
  );

  return (
    <>
      <Separator text={t('examinations:fmdExam.general.title')} />
      <Row>
        <Col md={6}>
          <Form.Group className="mb-2">
            <Form.Label>{t('examinations:fmdExam.general.date')}</Form.Label>
            <CustomDateTimePicker
              controlProps={{
                control,
                name: 'date',
                rules: { validate: v => (v !== undefined && v !== null) || 'required' },
                defaultValue: null,
              }}
              error={errors?.date}
              maxDate={new Date()}
              format="dd/MM/yyyy"
              placeholderText="dd/MM/yyyy"
              onChange={v => {
                setValue('date', v);
                clearErrors('date');
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <CustomRadioGroup
            label={t('examinations:fmdExam.general.result')}
            labelClass="d-block"
            items={values}
            inline={false}
            groupProps={{ className: 'mb-3' }}
            controlProps={{
              name: 'result',
              control,
              rules: { validate: v => v !== undefined || 'required' },
              defaultValue: data?.result,
            }}
            onChange={v => {
              clearErrors('result');
              setResultValue(v);
              setValue('result', v);
            }}
          />
        </Col>
      </Row>
      {resultValue === FMDExaminationResult.referedToRegionalDoctor ? (
        <Row>
          <Col md={2}>
            <CustomRadioGroup
              label={t('examinations:fmdExam.general.funding')}
              labelClass="d-block"
              items={fundingValues}
              inline={false}
              groupProps={{ className: 'mb-3' }}
              controlProps={{
                name: 'funding',
                control,
                rules: { validate: v => v !== undefined || 'required' },
                defaultValue: data?.funding,
              }}
              onChange={v => {
                setFunding(v);
                setValue('funding', v);
              }}
            />
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t('userRoles.regd')}</Form.Label>
              {regdList.length > 0 && (
                <Controller
                  name="regionalDoctor"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => {
                    return (
                      <CustomSelect
                        style={{ textAlign: 'left' }}
                        options={regdList}
                        value={value}
                        onBlur={onBlur}
                        contentRenderer={({ state }) => {
                          const { values } = state;

                          return (
                            <span className="text-size-smaller">
                              {values[0] &&
                                values[0].value &&
                                values[0].label + (values[0].info?.company ? ` (${values[0].info.company})` : '')}
                            </span>
                          );
                        }}
                        itemRenderer={({ item, methods }) => (
                          <span
                            role="option"
                            aria-selected="false"
                            className="react-dropdown-select-item"
                            onClick={() => {
                              methods.addItem(item);
                            }}
                          >
                            {item && item.value && item.label + (item.info?.company ? ` (${item.info.company})` : '')}
                          </span>
                        )}
                        onChange={(val: Array<any>) => {
                          onChange(val[0].value);
                        }}
                      />
                    );
                  }}
                />
              )}
            </Form.Group>
          </Col>
          {/* <Col>Institution</Col> */}
          <Col>
            <Form.Group className="mb-2">
              <Form.Label>{t('examinations:fmdExam.general.dateRange')}</Form.Label>
              <CustomDateTimePicker
                controlProps={{ control, name: 'dateRange' }}
                error={errors?.dateRange}
                minDate={new Date()}
                range={true}
                format="dd/MM/yyyy"
                placeholderText="dd/MM/yyyy - dd/MM/yyyy"
                onChange={v => {
                  setValue('dateRange', v);
                }}
              />
              {errors?.date && (
                <Form.Control.Feedback type="invalid">
                  {(errors?.date.message && t(`errors:validationMessages.${errors?.date.message}`)) ||
                    (errors?.date.type === 'required' && (
                      <span id={'error-date'}>{t('errors:validationMessages.required')}</span>
                    ))}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
      ) : null}
      {resultValue === FMDExaminationResult.wrongScreeningResults ? (
        <Row>
          <Col>
            <CustomFormGroup
              label={t('examinations:fmdExam.general.notes')}
              register={{ ...register('notes') }}
              groupProps={{ className: 'mb-2' }}
              fixedLabel={true}
              controlProps={{
                id: 'notes',
                name: 'notes',
                defaultValue: data?.notes || '',
                as: 'textarea',
              }}
            />
          </Col>
        </Row>
      ) : null}
      {resultValue === ResultValues.treatmentByFMD ? (
        <Row>
          <Col>
            <CustomFormGroup
              label={t('examinations:fmdExam.general.treatment')}
              register={{ ...register('treatment') }}
              groupProps={{ className: 'mb-2' }}
              fixedLabel={true}
              controlProps={{
                id: 'treatment',
                name: 'treatment',
                defaultValue: data?.treatment || '',
                as: 'textarea',
              }}
            />
          </Col>
        </Row>
      ) : null}
      {resultValue === ResultValues.treatmentByFMD && (
        <NextAppointment errors={errors} setValue={setValue} control={control} data={data} />
      )}
    </>
  );
}

General.propTypes = {};

export default General;
