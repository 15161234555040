import React from 'react';
import { Col, Form, FormLabel, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { REGDExaminationResult } from '../../../../../constants';
import { ExaminationDataType } from '../../Examination/Examination';
import Separator from '../../../../../components/Separator/Separator';
import SymptomsFormValues from '../../../../../components/SymptomsForm/SymptomsFormValues';
import StatusOculorum from './StatusOculorum';
import { AttachmentInfoType } from '../../../../../hooks/useCloudinaryWidget';

type Props = {
  data: REGDExaminationDataType;
};

type PrescriptionEyes = {
  os: {
    [key: string]: string;
  };
  od: {
    [key: string]: string;
  };
  dpp: string;
  value: number;
  [key: string]: string | any;
};
type REGDExamDataNestedSubType = {
  v?: boolean;
  status?: string;
  details?: string;
};

export type REGDExaminationDelusionSubType = {
  v?: boolean;
  deviations: {
    eyeballIsTiltedInwards: boolean;
    eyeballsAreDeflectedOutwards: boolean;
    eyeballIsTiltedUpwards: boolean;
    eyeballIsTiltedDownwards: boolean;
    other: REGDExamDataNestedSubType;
  }
};

export type REGDExaminationDataEyelidsSubType = {
  v?: boolean;
  deviations?: {
    erythema: REGDExamDataNestedSubType;
    swellingAndOrHematoma: REGDExamDataNestedSubType;
    other: REGDExamDataNestedSubType;
  };
};

export type REGDExaminationDataShagkapeniSubType = {
  v?: boolean;
  deviations?: {
    swelling: REGDExamDataNestedSubType;
    hypertension: REGDExamDataNestedSubType;
    production: REGDExamDataNestedSubType;
    inflammatoryReaction: REGDExamDataNestedSubType;
    dryWithFolds: REGDExamDataNestedSubType;
    other: REGDExamDataNestedSubType;
  };
};

export type REGDExaminationDataCorneaSubType = {
  v?: boolean;
  deviations?: {
    swellingOpacity: REGDExamDataNestedSubType;
    presenceOfScarsUnevenness: REGDExamDataNestedSubType;
    precipitates: REGDExamDataNestedSubType;
    other: REGDExamDataNestedSubType;
  };
  location?: string;
};

export type REGDExaminationDataFrontChamberSubType = {
  v?: boolean;
  deviations?: {
    primaryChamberDepth: REGDExamDataNestedSubType;
    inflammatoryCells: REGDExamDataNestedSubType;
    hyphema: REGDExamDataNestedSubType;
    hippopion: REGDExamDataNestedSubType;
    other: REGDExamDataNestedSubType;
  };
  size?: string;
};

export type REGDExaminationDataIrisSubType = {
  v?: boolean;
  deviations?: {
    partialOrCompleteAbsence: REGDExamDataNestedSubType;
    atrophy: REGDExamDataNestedSubType;
    backTacks: REGDExamDataNestedSubType;
    primaryAttachments: REGDExamDataNestedSubType;
    other: REGDExamDataNestedSubType;
  };
};

export type REGDExaminationDataPupilSubType = {
  v?: boolean;
  deviations?: {
    form: REGDExamDataNestedSubType;
    pupilReactionToLight: REGDExamDataNestedSubType;
    other: REGDExamDataNestedSubType;
  };
};

export type REGDExaminationDataLensSubType = {
  v?: boolean;
  deviations?: {
    blurry: REGDExamDataNestedSubType;
    posteriorLobeFibrosis: REGDExamDataNestedSubType;
    other: REGDExamDataNestedSubType;
  };
};

export type REGDExaminationDataVitreousBodySubType = {
  v?: boolean;
  deviations?: {
    cells: REGDExamDataNestedSubType;
    hemophthalmus: REGDExamDataNestedSubType;
    destruction: REGDExamDataNestedSubType;
    other: REGDExamDataNestedSubType;
  };
};

export type REGDExaminationDataManualExamSubType = {
  v?: boolean;
  deviations?: {
    [key: string]: REGDExamDataNestedSubType & {
      excavationDetails?: string;
    };
  };
};

export type REGDExaminationDataType = ExaminationDataType & {
  result: number;
  paperId: string;
  visitN: string;
  notes: string;
  dateRange: string;
  regionalDoctor: {
    fullName: string;
  };
  nextAppointment: {
    date: string;
  };
  nextAppointmentValue: any;
  symptoms: {
    [key: string]: boolean | string;
  };
  deseases: {
    [key: string]: boolean;
  };
  diagnosis: {
    r: {
      diseases: {
        [key: string]: boolean;
      };
    };
    l: {
      diseases: {
        [key: string]: boolean;
      };
    };
  };
  surgeries: {
    [key: string]: boolean;
  };
  delusionTest: {
    l: REGDExaminationDelusionSubType;
    r: REGDExaminationDelusionSubType;
  };
  visualAcuityAndRefraction: any;
  glassesPrescription: {
    [key in keyof PrescriptionEyes]: string;
  };
  stillLampExamState: boolean;
  movementsOfTheEyeballs: {
    l: {
      v: boolean;
      note?: string;
    }
    r: {
      v: boolean;
      note?: string;
    }
  }
  closeUpRefractionState: boolean;
  closeUpRefraction: {
    b: string;
  };
  eyelids: {
    l: REGDExaminationDataEyelidsSubType;
    r: REGDExaminationDataEyelidsSubType;
  };
  shagkapeni: {
    l: REGDExaminationDataShagkapeniSubType;
    r: REGDExaminationDataShagkapeniSubType;
  };
  cornea: {
    l: REGDExaminationDataCorneaSubType;
    r: REGDExaminationDataCorneaSubType;
  };
  frontChamber: {
    l: REGDExaminationDataFrontChamberSubType;
    r: REGDExaminationDataFrontChamberSubType;
  };
  iris: {
    l: REGDExaminationDataIrisSubType;
    r: REGDExaminationDataIrisSubType;
  };
  pupil: {
    l: REGDExaminationDataPupilSubType;
    r: REGDExaminationDataPupilSubType;
  };
  lens: {
    l: REGDExaminationDataLensSubType;
    r: REGDExaminationDataLensSubType;
  };
  vitreousBody: {
    l: REGDExaminationDataVitreousBodySubType;
    r: REGDExaminationDataVitreousBodySubType;
  };
  skiascopeNarrowApertureState: boolean;
  skiascopeWideApertureState: boolean;
  autorefractometryState: boolean;
  autorefractometryFiles: AttachmentInfoType[];
  intraocularPressureState: boolean;
  eyeExaminationState: boolean;
  intraocularPressure: {
    r: {
      v: boolean;
      result: string;
    };
    l: {
      v: boolean;
      result: string;
    };
  };
  eyeExamination: {
    r: REGDExaminationDataManualExamSubType;
    l: REGDExaminationDataManualExamSubType;
  };
  lacrimalSystem: {
    r: {
      v: boolean;
    };
    l: {
      v: boolean;
    };
  };
};

enum REGDExaminationDataTypeGlasses {
  v1 = 'no',
  v2 = 'forNearOrFar',
  v3 = 'forConstantWear',
}

function REGDExamValues({ data }: Props) {
  const { t } = useTranslation();

  // console.log(data);

  return (
    <Row>
      <Col className="py-3">
        <Separator text={`${t('examinations:regdExam.title')}`} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:regdExam.general.date')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark text-capitalize">{data.date}</span>
        </Form.Group>
        {/* <Form.Group>
          <FormLabel className="text-muted me-1">{t('examinations:regdExam.paperId')}{t('formLabelSeparator')}</FormLabel>
          <span className="text-dark text-capitalize">{data.paperId}</span>
        </Form.Group>
        <Form.Group>
          <FormLabel className="text-muted me-1">{t('examinations:regdExam.visitN')}{t('formLabelSeparator')}</FormLabel>
          <span className="text-dark text-capitalize">{data.visitN}</span>
        </Form.Group> */}
        <Separator text={`${t('examinations:regdExam.complaints')}`} variant="light" />
        <SymptomsFormValues data={data.symptoms} className="mb-3" />
        <Separator text={`${t('examinations:regdExam.deseases.history')}`} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:regdExam.deseases.title')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark text-capitalize">
            {Object.entries(data.deseases)
              .filter(_ => _[1] && _[0] !== 'notIdentifield')
              .map(_ => (_[0] === 'other' ? _[1] : t(`examinations:regdExam.deseases.${_[0]}`)))
              .join(', ')}
          </span>
        </Form.Group>
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:regdExam.surgeries.title')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark text-capitalize">
            {Object.entries(data.surgeries)
              .filter(_ => _[1] && _[0] !== 'notIdentifield')
              .map(_ => (_[0] === 'other' ? _[1] : t(`examinations:regdExam.surgeries.${_[0]}`)))
              .join(', ')}
          </span>
        </Form.Group>
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:regdExam.glassesPrescription.title')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark">
            {t(
              `examinations:regdExam.glassesPrescription.${
                REGDExaminationDataTypeGlasses['v' + data.glassesPrescription.value]
              }`
            )}
          </span>
          {(data.glassesPrescription.value == 2 || data.glassesPrescription.value === 3) && (
            <>
              {' ('}
              <span className="text-dark ml-4">
                <Trans
                  i18nKey="examinations:regdExam.odValues"
                  components={{
                    bold: <b></b>,
                    sph: data.glassesPrescription.od.sph,
                    cyl: data.glassesPrescription.od.cyl,
                    axis: data.glassesPrescription.od.axis,
                  }}
                />
              </span>,
              <span className="text-dark ml-4">
                {' '}
                <Trans
                  i18nKey="examinations:regdExam.osValues"
                  components={{
                    bold: <b></b>,
                    sph: data.glassesPrescription.os.sph,
                    cyl: data.glassesPrescription.os.cyl,
                    axis: data.glassesPrescription.os.axis,
                  }}
                />
              </span>,
              <span className="text-dark ml-4">
                {' '}
                <Trans
                  i18nKey="examinations:regdExam.dppValues"
                  components={{
                    bold: <b></b>,
                    dpp: data.glassesPrescription.dpp
                  }}
                />
              </span>
              {')'}
            </>
          )}
        </Form.Group>
        <Separator text={`${t('examinations:regdExam.diagnos')}`} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:rightEye')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark text-capitalize">
            {Object.entries(data.diagnosis.r.diseases)
              .filter(_ => (_[1] === true || typeof _[1] === 'string') && _[0] !== 'notIdentifield')
              .map(_ => (_[0] === 'other' ? _[1] : t(`examinations:regdExam.diagnosis.${_[0]}`)))
              .join(', ')}
          </span>
          {/* <span className="text-dark text-capitalize">{Object.entries(data.diagnosis.r.diseases).filter(_ => _[1].v).map(_ => t(`examinations:regdExam.diagnosis.${_[0]}`)).join(', ')}</span> */}
        </Form.Group>
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:leftEye')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark text-capitalize">
            {Object.entries(data.diagnosis.l.diseases)
              .filter(_ => (_[1] === true || typeof _[1] === 'string') && _[0] !== 'notIdentifield')
              .map(_ => (_[0] === 'other' ? _[1] : t(`examinations:regdExam.diagnosis.${_[0]}`)))
              .join(', ')}
          </span>
        </Form.Group>

        <StatusOculorum data={data} />

        <Separator text={`${t('examinations:regdExam.general.result')}`} variant="light" />
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:regdExam.general.result')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark text-capitalize">
            {t(`examinations:regdExam.general.resultValue${data.result}`)}
          </span>
        </Form.Group>
        {data.result === REGDExaminationResult.referedToRegionalDoctor ? (
          <>
            <Form.Group>
              <FormLabel className="text-muted me-1">
                {t('examinations:regdExam.general.funding')}
                {t('formLabelSeparator')}
              </FormLabel>
              <span className="text-dark text-capitalize">
                {t(`examinations:regdExam.general.fundingValue${data.result}`)}
              </span>
            </Form.Group>
            <Form.Group>
              <FormLabel className="text-muted me-1">
                {t('examinations:regdExam.general.regd')}
                {t('formLabelSeparator')}
              </FormLabel>
              <span className="text-dark text-capitalize">{data.regionalDoctor.fullName}</span>
            </Form.Group>
            <Form.Group>
              <FormLabel className="text-muted me-1">
                {t('examinations:regdExam.general.dateRange')}
                {t('formLabelSeparator')}
              </FormLabel>
              <span className="text-dark text-capitalize">{data.dateRange}</span>
            </Form.Group>
          </>
        ) : null}
        {data.result === REGDExaminationResult.wrongScreeningResults ? (
          <Form.Group>
            <FormLabel className="text-muted me-1">
              {t('examinations:regdExam.general.notes')}
              {t('formLabelSeparator')}
            </FormLabel>
            <div className="text-dark text-capitalize px-3 mb-3">{data.notes}</div>
          </Form.Group>
        ) : null}
        <Form.Group>
          <FormLabel className="text-muted me-1">
            {t('examinations:regdExam.general.nextAppointment')}
            {t('formLabelSeparator')}
          </FormLabel>
          <span className="text-dark text-capitalize">{data.nextAppointment ? t('yes') : t('no')}</span>
        </Form.Group>
        {data.nextAppointment ? (
          <Form.Group>
            <FormLabel className="text-muted me-1">
              {t('examinations:regdExam.general.nextAppointmentDate')}
              {t('formLabelSeparator')}
            </FormLabel>
            <span className="text-dark text-capitalize">{data.nextAppointment.date}</span>
          </Form.Group>
        ) : null}
      </Col>
    </Row>
  );
}

REGDExamValues.propTypes = {};

export default REGDExamValues;
